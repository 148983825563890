<template>
  <div>
    <v-card class="ma-2">

      <div class="d-flex justify-space-between px-4">
        <v-card-title>Clients</v-card-title>
        <v-btn class=" my-auto primary" @click="addClients()">Add client</v-btn>
      </div>
      <!-- v-dialog -->
      <v-dialog width="900" v-model="openDialogClient">
        <v-card>
          <v-card-title>
            <span v-if="update">Edit client</span>
            <span v-else>Add client</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col sm="6">
                  <v-text-field label="Login" outlined v-model="Client.login">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Password" outlined v-model="Client.password">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Trademark" outlined v-model="Client.tradeMark">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="SMS Name" outlined v-model="Client.smsName">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Merchant ID" outlined v-model="Client.merchantId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Terminal ID" outlined v-model="Client.terminalId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Humo Merchant ID" outlined v-model="Client.humoMerchantId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Humo Terminal ID" outlined v-model="Client.humoTerminalId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Credit Merchant ID" outlined v-model="Client.creditMerchantId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Credit Terminal ID" outlined v-model="Client.creditTerminalId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Humo Credit Merchant ID" outlined v-model="Client.humoCreditMerchantId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Humo Credit Terminal ID" outlined v-model="Client.humoCreditTerminalId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Autopay Uzcard MerchantId" outlined v-model="Client.autoPayUzcardMerchantId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Autopay Uzcard TerminalId" outlined v-model="Client.autoPayUzcardTerminalId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Autopay Humo MerchantId" outlined v-model="Client.autoPayHumoMerchantId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Autopay Humo TerminalId" outlined v-model="Client.autoPayHumoTerminalId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="commission" suffix="%" outlined v-model="Client.commission">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Min Amount" suffix="UZS" outlined v-model="Client.minAmount">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-select
                      v-model="Client.isWithCommission"
                      :items="[{id: 0, name: 'No'}, {id: 1, name: 'Yes'}]"
                      label="Is with comission"
                      item-text="name"
                      item-value="id"
                  ></v-select>
                </v-col>

                <v-col sm="6">
                  <v-select
                      v-model="Client.acceptCardType"
                      :items="acceptCardTypes"
                      label="Accept Card Type"
                      item-text="name"
                      item-value="id"
                  ></v-select>
                </v-col>

                <v-col sm="6">
                  <v-text-field label="Parent Name" outlined v-model="Client.parentName">
                  </v-text-field>
                </v-col>

                <v-col sm="6">
                  <v-select
                      v-model="Client.category"
                      :items="payCategories"
                      label="Payment Category"
                      item-text="name"
                      item-value="id"
                  ></v-select>
                </v-col>

                <v-col sm="6">
                  <v-select
                      v-model="Client.payPurpose"
                      :items="payPurposes"
                      label="Pay Purpose"
                      item-text="name"
                      item-value="id"
                  ></v-select>
                </v-col>
                <v-col sm="6">
                  <v-select
                      v-model="Client.humoGate"
                      :items="humoGates"
                      label="Humo Gate"
                      item-text="name"
                      item-value="id"
                  ></v-select>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="transCreditData" outlined v-model="Client.transCreditData">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="type" type="number" outlined v-model="Client.type">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="info_outline">
                <v-col sm="6">
                  <v-text-field label="Paynet Username" outlined v-model="Client.paynetUsername">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Paynet Password" outlined v-model="Client.paynetPassword">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Paynet Terminal ID" outlined v-model="Client.paynetTerminalId">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Paynet URL" outlined v-model="Client.paynetUrl">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12">
                  <v-text-field label="Main Sales Channel" outlined v-model="Client.mainSalesChannel">
                  </v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-checkbox class="mr-4" v-model="Client.isPayAcceptWithoutSms" label="isPayAcceptWithoutSms">
                  </v-checkbox>
                </v-col>
                <v-col sm="4">
                  <v-checkbox class="mr-4" v-model="Client.payWithoutOtp" label="payWithoutOtp">
                  </v-checkbox>
                </v-col>
                <v-col sm="4">
                  <v-checkbox class="mr-4" v-model="Client.onlyOwnCards" label="onlyOwnCards">
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-btn style="width: 100%; color: red;" @click="openDialogClient = false">
                    cancel
                  </v-btn>
                </v-col>
                <v-col sm="6">
                  <v-btn v-if="update" style="width: 100%; color:orange" @click="EditClients()">
                    Edit
                  </v-btn>
                  <v-btn v-else style="width: 100%; color:green" @click="saveClients()">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- v-dialog -->
    </v-card>
    <v-expansion-panels class="ma-2">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h4>Filter</h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-text-field
                  label="searchKeyword"
                  outlined
                  v-model="body.searchKeyword"
                  @keydown.enter="body.page = 1, getClients()"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="Terminal ID"
                  outlined
                  v-model="body.terminalId"
                  @keydown.enter="body.page = 1, getClients()"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-btn
                  @click="body.page = 1, getClients()"
                  color="primary"
              >Search
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                  @click="updateList()"
                  color="warning"
              >Update list
              </v-btn>
            </v-col>
          </v-row>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="ma-2 pa-4">
      <!-- <v-table> -->
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="clientsList"
            :loading="isLoading"
            :items-per-page="body.count"
            :loading-text="'Loading...'"
            hide-default-footer
        >
          <template v-slot:item.createdDate="{item}">
            <div>{{ item.createdDate | dateTimeFormat }}</div>
          </template>
          <template v-slot:item.isDeleted="{item}">
            <div class="d-flex justify-space-between">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs}">
                  <v-icon
                      @click="reRegisterClientEpos(item.id)"
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                  >mdi-update
                  </v-icon>
                </template>
                <span>Re-Register</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs}">

                  <v-icon @click="toggleActivation(item.id)" class="cursor-pointer mr-4 pa-0" style="color: #ff9800"
                          v-bind="attrs"
                          v-on="on">
                    {{ item.isDeleted ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                  </v-icon>
                </template>
                <span>Toggle switch</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs}">
                  <v-btn @click="openPassword(item.id)" icon color="green" small
                         v-bind="attrs"
                         v-on="on">
                    <v-icon small> mdi-lock</v-icon>
                  </v-btn>
                </template>
                <span>Update password</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs}">
                  <v-btn @click="openAccessesModal(item.id)" icon color="blue" small
                         v-bind="attrs"
                         v-on="on">
                    <v-icon small> mdi-lock-check</v-icon>
                  </v-btn>
                </template>
                <span>Change permissions</span>
              </v-tooltip>

              <v-btn @click="getClient(item.id)" icon color="#397dfb" small>
                <v-icon small> mdi-pencil</v-icon>
              </v-btn>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs}">
                  <v-btn :to="{name: 'clientsView', params: {id: item.id}}" icon color="warning" small
                         v-bind="attrs"
                         v-on="on">
                    <v-icon small> mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View branches</span>
              </v-tooltip>

            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="text-center" v-if="clientsList.length > 0">
        <v-pagination
            v-model="body.page"
            :length="totalCount"
            :total-visible="10"
        ></v-pagination>
      </div>
      <!-- </v-table> -->
    </v-card>
    <v-dialog width="500" v-model="openPasswordDialog">
      <v-card>
        <v-card-title>
          update password
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                  outlined
                  label="Update client password"
                  v-model="clientPassword"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn style="width: 100%" @click="openPasswordDialog = false" color="error">
                cancel
              </v-btn>
            </v-col>
            <v-col>
              <v-btn style="width: 100%" color="success" @click="updatePasswordClient()">
                update
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog width="800" v-model="permissionsDialog">
      <v-card>
        <v-card-title>
          Edit permissions
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-group
                v-for="(item, itemIndex) in permissionItems"
                :key="itemIndex"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </template>
              <div class="pa-4">
                <select-with-check-box
                    v-model="clientAccesses.permissionIds"
                    :items="item.items"
                    label="Permissions"
                    item-text="name"
                    item-value="id"/>
              </div>
            </v-list-group>
          </v-list>
          <v-row>
            <v-col>
              <v-btn style="width: 100%" @click="permissionsDialog = false" color="error">
                cancel
              </v-btn>
            </v-col>
            <v-col>
              <v-btn style="width: 100%" color="success" @click="changeClientAccesses()">
                update
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Treeselect from '@riophae/vue-treeselect'
// import the styles
// import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "ClientSecond",
  components: {
    // Treeselect
  },
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: false},
        {text: 'Create', value: 'createdDate', align: 'center', sortable: false},
        {text: 'Login', value: 'login', align: 'center', sortable: false},
        {text: 'TradeMark', value: 'tradeMark', align: 'center', sortable: false},
        {text: 'Humo', value: 'humo', align: 'center', sortable: false},
        {text: 'svGate', value: 'svGate', align: 'center', sortable: false},
        {text: 'isDeleted', value: 'isDeleted', align: 'center', sortable: false}
      ],
      openPasswordDialog: false,
      openDialogClient: false,
      clientsList: [],
      update: false,
      permissionItems: [], //bulding
      permissionIds: [],
      accessOptions: [],
      clientPassword: '',
      clientPassId: 0,
      Client: {
        login: '',
        tradeMark: '',
        password: '',
        merchantId: '',
        terminalId: '',
        humoMerchantId: '',
        humoTerminalId: '',
        creditMerchantId: '',
        creditTerminalId: '',
        humoCreditMerchantId: '',
        humoCreditTerminalId: '',
        autoPayUzcardTerminalId: '',
        autoPayUzcardMerchantId: '',
        autoPayHumoTerminalId: '',
        autoPayHumoMerchantId: '',
        category: null,
        smsName: '',
        parentName: '',
        commission: 1,
        isWithCommission: 0, // int boolean
        minAmount: 500,
        acceptCardType: 1,
        isPayAcceptWithoutSms: true,
        payWithoutOtp: true,
        onlyOwnCards: true,
        payPurpose: 0,
        transCreditData: '',
        humoGate: 1,
        type: 0,
        paynetUsername: '',
        paynetPassword: '',
        paynetTerminalId: '',
        paynetUrl: '',
        mainSalesChannel: '',
      },
      body: {
        searchKeyword: '',
        terminalId: '',
        isActive: null,
        page: 1,
        count: 20
      },
      clientAccesses: {
        id: 0,
        permissionIds: []
      },
      payPurposes: [
        {id: 0, name: "Payment"},
        {id: 1, name: "Loan"},
        {id: 2, name: "EWallet"},
        {id: 3, name: "Exchange"},
        {id: 4, name: "Deposit"},
        {id: 5, name: "QrGci"},
        {id: 6, name: "P2PExternal"}
      ],
      humoGates: [
        {id: 0, name: "UniSoft"},
        {id: 1, name: "MyUzcard"}
      ],
      acceptCardTypes: [
        {
          id: 0,
          name: "All"
        },
        {
          id: 1,
          name: "Private and Pride"
        },
        {
          id: 2,
          name: "Corporate"
        }
      ],
      payCategories: [
        { id: 0, name: "Mobile Operators" },
        { id: 1, name: "Internet Providers" },
        { id: 2, name: "Municipal Services" },
        { id: 3, name: "Government Services And Fines" },
        { id: 4, name: "Telephony" },
        { id: 5, name: "Television And Online Broadcasting" },
        { id: 6, name: "Online Services" },
        { id: 7, name: "Advertisements And Promotions" },
        { id: 8, name: "Bank Credit Repayment" },
        { id: 9, name: "Loan And Installment Repayment" },
        { id: 10, name: "Transportation" },
        { id: 11, name: "Charity" },
        { id: 12, name: "Education" },
        { id: 13, name: "Electronic Wallets" },
        { id: 14, name: "Insurance" },
        { id: 15, name: "Games And Social Networks" },
        { id: 16, name: "Hosting Providers" },
        { id: 17, name: "Air And Rail Tickets" },
        { id: 18, name: "Online Stores" },
        { id: 19, name: "Financial Services" },
        { id: 20, name: "Real Estate" },
        { id: 21, name: "Medicine" },
        { id: 22, name: "Video Surveillance" },
        { id: 23, name: "Legal Services" },
        { id: 24, name: "Home Services" },
        { id: 25, name: "Hotels And Tourism" },
        { id: 26, name: "Sports" },
        { id: 27, name: "On Site Payments" },
        { id: 28, name: "Other Services" },
        { id: 29, name: "P2P Transactions" },
        { id: 30, name: "Marketplace Availability" },
        { id: 31, name: "Bank Card Issuance" },
        { id: 32, name: "Electronic Money Issuance" },
        { id: 33, name: "Cross Border Transfers" },
        { id: 34, name: "Payment To Bank Account" },
        { id: 35, name: "Currency Exchange" },
        { id: 36, name: "ATM Withdrawal" },
        { id: 37, name: "Bank Card Top Up" },
        { id: 38, name: "Auto Loan Cancellation Services" },
        { id: 39, name: "Scoring Services" }
      ],
      totalCount: 1,
      isLoading: false,
      permissionsDialog: false
    }
  },
  // mounted() {
  //   this.Id = this.$route.params.id
  // },
  methods: {
    addClients() {
      this.openDialogClient = true
      this.update = false
      // this.resetForm();
    },
    resetForm() {
      this.Client = {
        login: '',
        tradeMark: '',
        password: '',
        clientId: '',
        terminalId: '',
        humoMerchantId: '',
        humoTerminalId: '',
        creditMerchantId: '',
        creditTerminalId: '',
        humoCreditMerchantId: '',
        humoCreditTerminalId: '',
        autoPayUzcardMerchantId:'',
        autoPayUzcardTerminalId:'',
        autoPayHumoMerchantId:'',
        autoPayHumoTerminalId:'',
        smsName: '',
        parentName: '',
        category: null,
        commission: 0,
        isWithCommission: 0, // int boolean
        minAmount: 0,
        acceptCardType: 1,
        isPayAcceptWithoutSms: false,
        payWithoutOtp: false,
        onlyOwnCards: false,
        payPurpose: 0,
        transCreditData: '',
        humoGate: 1,
        type: 0,
      }
    },
    async getClients() {
      try {
        this.isLoading = true
        const res = await this.$http.post(`Client/getClients`, this.body);
        if (!res.error) {
          this.clientsList = res.result.items
          this.totalCount = Math.ceil(res.result.allCount / this.body.count)
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    async updateList() {
      try {
        this.isLoading = true
        const res = await this.$http.get(`Client/updateGateClients`);
        if (!res.error) {
          await this.getClients();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    async getClient(id) {
      try {
        const res = await this.$http.get(`Client/getClient?clientId=${id}`);
        if (!res.error) {
          this.openDialogClient = true
          this.update = true
          this.Client = res.result
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async EditClients(id) {
      this.openDialogClient = false
      // this.permission = this.Client.permissionIds
      try {
        const res = await this.$http.post(`Client/editClient`, this.Client);
        if (!res.error) {
          this.dialog = true;
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveClients() {
      this.Client.type = parseInt(this.Client.type);
      try {
        const res = await this.$http.post('Client/addClient', this.Client);
        if (!res.error) {
          this.clientsList = res.result;
          this.openDialogClient = false
          await this.updateList();
          this.successNotification();
        }
      } catch (e) {
        console.log(e.response.data)
        this.errorNotification(e.response.data.error.errorMessage)
      }
    },
    openPassword(id) {
      this.openPasswordDialog = true
      this.clientPassId = id
    },
    async updatePasswordClient() {
      this.openPasswordDialog = false
      try {
        const res = await this.$http.post(`Client/updateClientPassword`, {
          id: this.clientPassId,
          password: this.clientPassword
        })
        this.clientPassId = 0
        if (res.result && res.result.success) {
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async toggleActivation(id) {
      try {
        const res = await this.$http.get(`Client/toggleClientActivation?clientId=${id}`)
        if (!res.error) {
          this.successNotification();
          // console.log(res.result.clients);
          this.clientsList = res.result.clients
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async reRegisterClientEpos(id) {
      try {
        const res = await this.$http.get(`Client/reRegisterClientEpos?clientId=${id}`)
        if (!res.error) {
          this.successNotification();
          console.log(res.result);
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async openAccessesModal(id) {
      try {
        const res = await this.$http.get(`Client/getClient?clientId=${id}`);
        this.clientAccesses.id = res.result.id;
        this.clientAccesses.permissionIds = res.result.permissionIds;
        this.permissionsDialog = true;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async changeClientAccesses() {
      try {
        const res = await this.$http.post(`Client/changeClientAccesses`, this.clientAccesses)
        if (res.result) {
          this.permissionsDialog = false;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async getList() {
      await this.$http.get(`Client/getAccesses`).then((res) => {
        this.permissionItems = res.result.groups
        // const transformed = res.result.groups.map(({ id: id, name: label,  items: children}) => ({ id, label, children: children.map(({ id: id, name: label }) => ({ id, label })) }));
        // this.accessOptions = transformed
      })
          .catch((e) => {
            console.log(e)
          });
    }

  },

  created() {
    this.getClients();
    this.getList()
    // this.changeClientAccesses()
  },
  watch: {
    'body.page': {
      handler: function (val, oldVal) {
        this.getClients();
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.t-d-none {
  text-decoration: underline
}
</style>


